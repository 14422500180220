/*
 * SPDX-FileCopyrightText: 2023 Sefa Eyeoglu <contact@scrumplex.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */

export default function ready() {
    return new Promise((resolve) => {
        if (document.readyState !== "loading") resolve();
        else document.addEventListener("DOMContentLoaded", resolve);
    });
}
