/**!
 * SPDX-FileCopyrightText: 2023 Sefa Eyeoglu <contact@scrumplex.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */

import { tsParticles } from "@tsparticles/engine";
import { loadAngleUpdater } from "@tsparticles/updater-angle";
import { loadBaseMover } from "@tsparticles/move-base";
import { loadCircleShape } from "@tsparticles/shape-circle";
import { loadColorUpdater } from "@tsparticles/updater-color";
import { loadOpacityUpdater } from "@tsparticles/updater-opacity";
import { loadSizeUpdater } from "@tsparticles/updater-size";

import options from "./tsParticles-config.json";
import ready from "./_utils";

const { matches } = matchMedia("(min-width: 768px)");

async function loadParticles() {
    await loadAngleUpdater(tsParticles);
    await loadBaseMover(tsParticles);
    await loadCircleShape(tsParticles);
    await loadColorUpdater(tsParticles);
    await loadOpacityUpdater(tsParticles);
    await loadSizeUpdater(tsParticles);
    tsParticles
        .load({ id: "particles-js", options })
        .then()
        .catch((e) => {
            console.error("tsparticles failed to load :(");
            console.error(e);
        });
}

if (matches) {
    ready().then(() => {
        // tsParticles causes heavy lag while loading, lets delay it, so that all animations are smooth at least
        setTimeout(loadParticles, 500);
    });
}
